<template>
  <div id="ecbox">
    <div class="mb-md">
      <div class="left">
        <el-upload class="upload-demo" action="/home/file/upload"
         :headers="{token}"
          :data='{userName,userPhone}'
         :on-change="getWorkbook">
          <el-button type="primary">点击上传</el-button>
        </el-upload>
      </div>
      <div class="right">
        <el-button type="primary" v-show="false" @click="exportJson"
          >导出JSON</el-button
        >
        <el-button type="primary" @click="exportExcel">导出xlsx</el-button>
        <el-button type="primary" @click="uploadExcel">上传xlsx</el-button>
        <el-button type="primary" v-show="false" @click="downloadExcel"
          >下载xlsx</el-button
        >
      </div>
    </div>
    <!--web spreadsheet组件-->
    <div id="x-spreadsheet-demo"></div>
  </div>
</template>

<script>
//引入依赖包
import zhCN from "x-data-spreadsheet/src/locale/zh-cn";
import Spreadsheet from "x-data-spreadsheet";
import XLSX from "xlsx";
import axios from "axios";
//设置中文
Spreadsheet.locale("zh-cn", zhCN);
export default {
  name: "xspreadsheet-demo",
  data() {
    return {
     
   userName:"",
    userPhone :"",
    token :"",
      fileName: "",
      xs: null,
      jsondata: {
        type: "",
        label: "",
      },
    };
  },

  methods: {
    handleChange(file, fileList) {
      console.log(file, fileList);
    },
    init() {
      let ecboxWidth = document.querySelector("#ecbox").clientWidth;
      let ecboxHeight = document.querySelector("#ecbox").clientHeight
      console.log(ecboxWidth);
      const rows10 = { len: 100000 };
      const rows = { len: 800 };
      this.xs = new Spreadsheet("#x-spreadsheet-demo", {
        showToolbar: true,
        showGrid: true,
        view:{
          height:()=>document.documentElement.clientHeight - 186,
          width:()=>ecboxWidth
        }
      })
        .loadData([
          {
            cols: {
              len: 100,
            },
            rows,
          },
        ])
        .change((cdata) => {
          // console.log(cdata);
          console.log(">>>", this.xs.getData());
        });

      this.xs
        .on("cell-selected", (cell, ri, ci) => {
          console.log("cell:", cell, ", ri:", ri, ", ci:", ci);
        })
        .on("cell-edited", (text, ri, ci) => {
          console.log("text:", text, ", ri: ", ri, ", ci:", ci);
        });

      setTimeout(() => {
        // xs.loadData([{ rows }]);
        // xs.cellText(14, 3, 'cell-text').reRender();
        // console.log('cell(8, 8):', this.xs.cell(8, 8));
        // console.log('cellStyle(8, 8):', this.xs.cellStyle(8, 8));
      }, 5000);
    },
    loadExcelFile(fileSelected) {
      var workbook_object = this.getWorkbook(fileSelected);

      this.xs.loadData(this.stox(workbook_object));
    },
    /** 导出excel */
    exportExcel() {
      var new_wb = this.xtos(this.xs.getData());
      /* generate download */
      XLSX.writeFile(new_wb, this.fileName + ".xlsx");
    },
    exportJson() {
      console.log(this.xs.getData());
    },
    /** 下载并读取excel */
    downloadExcel() {
      axios
        .get("http://localhost:8088/api/v1/test/test1", {
          responseType: "arraybuffer",
        })
        .then((res) => {
          if (res.status == 200) {
            var data = res.data;
            console.log("data", data);
            var data = new Uint8Array(data);
            var workbook = XLSX.read(data, { type: "array" });
            this.xs.loadData(this.stox(workbook));
          }
        });
    },
    /** 上传excel */
    uploadExcel() {
      var new_wb = this.xtos(this.xs.getData());
      var wbout = XLSX.write(new_wb, { type: "binary" });
      console.log("new_wb", new_wb);
      var file = new Blob([this.s2ab(wbout)]);
      var forms = new FormData();
      var configs = {
        headers: { "Content-Type": "multipart/form-data" ,token:this.token},
      };
      forms.append("file", file);
      forms.append('userName',this.userName)
      forms.append('userPhone',this.userPhone)
      axios
        .post("/home/file/upload", forms, configs)
        .then((res) => {
          console.log(res);
        }); 
    },
    s2ab(s) {
      var buf = new ArrayBuffer(s.length);
      var view = new Uint8Array(buf);
      for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
      return buf;
    },
    /** 将x-data-spreadsheet中的数据格式转为xlsx中的workbook */
    xtos(sdata) {
      console.log(sdata);
      var out = XLSX.utils.book_new();
      sdata.forEach(function (xws) {
        var aoa = [[]];
        var rowobj = xws.rows;
        for (var ri = 0; ri < rowobj.len; ++ri) {
          var row = rowobj[ri];
          if (!row) continue;
          aoa[ri] = [];
          Object.keys(row.cells).forEach(function (k) {
            var idx = +k;
            if (isNaN(idx)) return;
            aoa[ri][idx] = row.cells[k].text;
          });
        }
        var ws = XLSX.utils.aoa_to_sheet(aoa);

        /** 读取在线中的合并单元格，并写入导出的数据中
                 * merges: Array(19)
                    0: "A16:P16"
                    1: "A17:P17"
                    2: "O2:P2"
                    3: "F2:G2"
                 */
        ws["!merges"] = [];
        xws.merges.forEach((merge) => {
          ws["!merges"].push(XLSX.utils.decode_range(merge));
        });

        XLSX.utils.book_append_sheet(out, ws, xws.name);
      });
      return out;
    },
    stox(wb) {
      var out = [];
      wb.SheetNames.forEach(function (name) {
        var o = { name: name, rows: {}, merges: [] };
        var ws = wb.Sheets[name];
        var aoa = XLSX.utils.sheet_to_json(ws, { raw: false, header: 1 });
        aoa.forEach(function (r, i) {
          var cells = {};
          r.forEach(function (c, j) {
            cells[j] = { text: c };
          });
          o.rows[i] = { cells: cells };
        });
        // 设置合并单元格

        out.push(o);
      });
      return out;
    },
    /**
     * 获取文件
     * @param fileSelected
     */
    getWorkbook(fileSelected) {
      console.log("fileSelected", fileSelected.name);
      this.fileName = fileSelected.name.split(".")[0];
      console.log(name);
      let file = fileSelected.raw;
      let reader = new FileReader();
      reader.onload = (e) => {
        let data = e.target.result,
          fixedData = this.fixData(data),
          workbook = XLSX.read(btoa(fixedData), { type: "base64" });
        this.xs.loadData(this.stox(workbook));
        // console.log("workbook",workbook)
        console.log("fixedData", fixedData);
        // console.log("this.stox(workbook)",this.stox(workbook))
      };
      reader.readAsArrayBuffer(file);
      // return workbook
    },
    fixData(data) {
      var o = "",
        l = 0,
        w = 10240;
      for (; l < data.byteLength / w; ++l)
        o += String.fromCharCode.apply(
          null,
          new Uint8Array(data.slice(l * w, l * w + w))
        );
      o += String.fromCharCode.apply(null, new Uint8Array(data.slice(l * w)));
      return o;
    },
  },
  mounted(){
    this.init();
    let info  = JSON.parse(window.localStorage.getItem('userInfo')||'{}')
    this.userName = info.user.userName
    this.userPhone = info.user.userPhone
    this.token = info.token;
  }
};
</script>
<style scoped>
#x-spreadsheet-demo {
  width: 100%;
}
.ecbox {
  overflow: hidden;
}
.mb-md {
  display: flex;
  justify-content: space-between;
  margin: 5px 0;
}

</style>